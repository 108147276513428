html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,input,select,textarea,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video,:after{
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  -ms-box-sizing:border-box;
  -o-sizing:border-box;
  padding:0;
  border:0;
  margin:0;
  font-size:100%;
  vertical-align:baseline;
}
.LandingPage {
  width:100%;
  height:100%;
  min-width:320px;
  padding:0;
  text-align:center;
  margin:0;
  overflow-y:auto;
  overflow-x:hidden;
  -moz-font-smoothing:antialiased;
  -webkit-font-smoothing:antialiased;
  -ms-font-smoothing:antialiased;
  position:relative;
  font:400 18px/28px F37 Ginger, Arial, sans-serif;
  color:#b3c5bc;
  /*background: none;/**/
  /*background: transparent;*/
  background-color: #013D1F;
  /*background-color: #4f5358;*/
}
html{
  width:100%;
  height:100%;
  min-width:320px;
  -moz-text-size-adjust:100%;
  -webkit-text-size-adjust:100%;
  -ms-text-size-adjust:100%
}
/**{
  cursor:default
}*/
.landing-page-body {
  background-color: #013D1F;
}

.landing-page-body img{
  max-width:100%;
  height:auto;
  border:0;
  vertical-align:bottom
}
strong{
  font-weight:700
}
ul{
  list-style:none
}
a{
  outline:none;
  text-decoration:none
}
a,a *,button,select,input[type=button],input[type=submit]{
  cursor:pointer;
  transition:all 350ms ease;
  -moz-transition:all 350ms ease;
  -webkit-transition:all 350ms ease
}
button,input[type=button],input[type=submit],select,textarea{
  border-radius:0;
  -moz-border-radius:0;
  -webkit-border-radius:0;
  -ms-border-radius:0;
  -o-border-radius:0;
  appearance:none;
  -moz-appearance:none;
  -webkit-appearance:none;
  -ms-appearance:none;
  -o-appearance:none
}
input[type=text],input[type=email],input[type=tel],input[type=url],textarea{
  cursor:text;
  user-select:auto !important;
  -moz-user-select:auto !important;
  -webkit-user-select:auto !important;
  -ms-user-select:auto !important
}
input,select,textarea,button{
  outline:none
}
select option{
  font-weight:inherit;
  font-size:inherit
}
select::-ms-expand{
  display:none
}
input[type=number]{
  -moz-appearance:textfield
}
input[type=submit],input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{
  -moz-appearance:none;
  -webkit-appearance:none;
  -ms-appearance:none;
  -o-appearance:none;
  margin:0
}
textarea{
  overflow:auto
}
:-moz-placeholder{
  color:inherit;
  opacity:1
}
::-moz-placeholder{
  color:inherit;
  opacity:1
}
::-webkit-input-placeholder{
  color:inherit
}
:-ms-input-placeholder{
  color:inherit
}
::-ms-input-placeholder{
  color:inherit
}
button::-moz-focus-inner,input[type=submit]::-moz-focus-inner{
  border:0;
  padding:0
}
/*@font-face{
  font-family:'GoPaperless';
  src:url("F37 Ginger Light") format("woff2"),url("fonts/GoPaperlessRegular.woff") format("woff");
  font-weight:400;
  font-style:normal;
  font-display:swap
}
@font-face{
  font-family:'GoPaperless';
  src:url("fonts/GoPaperlessBold.woff2") format("woff2"),url("fonts/GoPaperlessBold.woff") format("woff");
  font-weight:700;
  font-style:normal;
  font-display:swap
}
@font-face{
  font-family:'GoPaperless';
  src:url("fonts/GoPaperlessLight.woff2") format("woff2"),url("fonts/GoPaperlessLight.woff") format("woff");
  font-weight:200;
  font-style:normal;
  font-display:swap
}*/
.landing-page-body .screen-reader-response{
  display:none
}
span.wpcf7-list-item-label::before,span.wpcf7-list-item-label::after{
  content:''
}
.landing-page-body .wpcf7-display-none{
  display:none
}
div.wpcf7 .ajax-loader{
  visibility:hidden
}
div.wpcf7 .ajax-loader.is-active{
  visibility:visible
}
div.wpcf7 div.ajax-error{
  display:none
}

::-webkit-scrollbar{
  width:8px
}
::-webkit-scrollbar-track{
  background:transparent
}
::-webkit-scrollbar-thumb{
  border-radius:5px;
  -moz-border-radius:5px;
  -webkit-border-radius:5px;
  background:#4f5358
}
::selection{
  color:inherit;
  background:rgba(0,0,0,0.2)
}
::-moz-selection{
  color:inherit;
  background:rgba(0,0,0,0.2)
}
.landing-page-body .center{
  position:relative;
  display:block;
  width:100%;
  max-width:1250px;
  padding:0 10px;
  margin:auto
}
.landing-page-body .center:after{
  display:block;
  clear:both;
  content:''
}
strong{
  font-weight:700
}
.hr{
  clear:both;
  height:1px;
  border:0;
  font-size:0;
  color:transparent;
  margin:50px auto;
  background:#34644c
}
.landing-page-body .nowrap{
  white-space:nowrap
}
.landing-page-body .button{
  position:relative;
  display:inline-flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  min-width:250px;
  max-width:100%;
  height:50px;
  border-radius:999px;
  -moz-border-radius:999px;
  -webkit-border-radius:999px;
  padding:12px 40px 8px;
  font-weight:700;
  font-size:14px;
  line-height:18px;
  text-transform:uppercase;
  text-align:center;
  letter-spacing:0.025em;
  color:#fff;
  background:#76b83f;
  box-shadow:0 10px 20px rgba(0,0,0,0.25);
  -moz-box-shadow:0 10px 20px rgba(0,0,0,0.25);
  -webkit-box-shadow:0 10px 20px rgba(0,0,0,0.25)
}
.landing-page-body .button::selection{
  background:transparent
}
.landing-page-body .button::-moz-selection{
  background:transparent
}
.landing-page-body .button:hover{
  color:#012814;
  background:#b2e155
}
.landing-page-body .hero{
  position:relative;
  display:flex;
  flex-wrap:wrap;
  flex-direction:column;
  align-items:center;
  width:100%;
  height:100vh;
  max-height:1150px;
  min-height:900px;
  padding:25px 0 5px;
  overflow:hidden;
  margin:0 0 100px;
  background:#002812
}
.landing-page-body .hero .header{
  position:relative;
  margin:auto;
  z-index:20
}
.landing-page-body .hero .header h1{
  font-weight:700;
  font-size:38px;
  line-height:45px;
  color:#fff;
  margin:0 auto
}
.landing-page-body .hero .header h1 span{
  color:#76b83f
}
.landing-page-body .hero .header p{
  font-weight:700;
  font-size:16px;
  line-height:22px;
  letter-spacing:0.0075em;
  margin:22px auto
}
.landing-page-body .hero .header .button{
  margin:25px auto 15px
}
.landing-page-body .hero .benefits{
  position:relative;
  height:550px;
  margin:auto;
  z-index:10
}
.landing-page-body .hero .benefits .info{
  position:relative;
  height:550px;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  padding:26px 20px 44px
}
.landing-page-body .hero .benefits .info li{
  width:50%
}
.landing-page-body .hero .benefits .info li .padding{
  position:relative;
  cursor:default;
  z-index:20
}
.landing-page-body .hero .benefits .info li .padding .icon{
  position:absolute;
  top:22px;
  width:64px;
  height:64px;
  border:2px rgba(255,255,255,0.2) solid;
  border-radius:999px;
  -moz-border-radius:999px;
  -webkit-border-radius:999px;
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size:40px 40px;
  transition:all 350ms ease;
  -moz-transition:all 350ms ease;
  -webkit-transition:all 350ms ease
}
.landing-page-body .hero .benefits .info li .padding h2{
  font-weight:700;
  font-size:16px;
  line-height:24px;
  text-transform:uppercase;
  letter-spacing:0.01em;
  color:#fff;
  margin:0 auto 10px
}
.landing-page-body .hero .benefits .info li .padding p{
  font-size:13px;
  line-height:20px
}
.landing-page-body .hero .benefits .info li .padding .line{
  position:absolute;
  bottom:0;
  width:0;
  height:1px;
  background:transparent;
  transition:all 750ms ease;
  -moz-transition:all 750ms ease;
  -webkit-transition:all 750ms ease
}
.landing-page-body .hero .benefits .info li .padding .line .dot{
  position:absolute;
  top:-12px;
  width:25px;
  height:25px;
  border-radius:999px;
  -moz-border-radius:999px;
  -webkit-border-radius:999px;
  background:#fff;
  transform:scale(0);
  -moz-transform:scale(0);
  -webkit-transform:scale(0);
  box-shadow:0 0 0 0 transparent;
  -moz-box-shadow:0 0 0 0 transparent;
  -webkit-box-shadow:0 0 0 0 transparent;
  transition:all 750ms ease;
  -moz-transition:all 750ms ease;
  -webkit-transition:all 750ms ease
}
.landing-page-body .hero .benefits .info li:nth-of-type(odd){
  padding:0 170px 0 0;
}
.landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding{
  padding:25px 0 25px 90px;
  text-align:left;
}
.landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .icon{
  left:0;
}
.landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .line{
  left:30px;
}
.landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .line .dot{
  right:-12px;
}
.landing-page-body .hero .benefits .info li:nth-of-type(even){
  padding:0 0 0 170px;
}
.landing-page-body .hero .benefits .info li:nth-of-type(even) .padding{
  padding:25px 90px 25px 0;
  text-align:right;
}
.landing-page-body .hero .benefits .info li:nth-of-type(even) .padding .icon{
  right:0;
}
.landing-page-body .hero .benefits .info li:nth-of-type(even) .padding .line{
  right:30px;
}
.landing-page-body .hero .benefits .info li:nth-of-type(even) .padding .line .dot{
  left:-12px;
}
.landing-page-body .hero .benefits .info li.active .padding .icon{
  border:0 transparent solid;
  background-color:#76b83f;
  animation:icon 1500ms ease;
  -moz-animation:icon 1500ms;
  -webkit-animation:icon 1500ms;
}
@keyframes icon{
  0%{
    transform:rotateY(0deg)
  }
  100%{
    transform:rotateY(-360deg)
  }
}
@-moz-keyframes icon{
  0%{
    -moz-transform:rotateY(0deg)
  }
  100%{
    -moz-transform:rotateY(-360deg)
  }
}
@-webkit-keyframes icon{
  0%{
    -webkit-transform:rotateY(0deg)
  }
  100%{
    -webkit-transform:rotateY(-360deg)
  }
}
.landing-page-body .hero .benefits .info li.active .padding .line{
  width:100%;
  background:rgba(255,255,255,0.2)
}
.landing-page-body .hero .benefits .info li.active .padding .line .dot{
  background:#76b83f;
  transform:scale(1);
  -moz-transform:scale(1);
  -webkit-transform:scale(1);
  box-shadow:0 0 0 4px rgba(255,255,255,0.15);
  -moz-box-shadow:0 0 0 4px rgba(255,255,255,0.15);
  -webkit-box-shadow:0 0 0 4px rgba(255,255,255,0.15)
}
.landing-page-body .hero .benefits .gallery{
  position:absolute;
  top:0;
  left:50%;
  width:256px;
  height:550px;
  overflow:hidden;
  margin:0 0 0 -128px;
  background:#222
}
.landing-page-body .hero .benefits .gallery ul{
  display:flex;
  flex-wrap:nowrap;
  width:1536px
}
.landing-page-body .hero .benefits .gallery ul li{
  opacity:.5;
  -moz-opacity:.5;
  -webkit-opacity:.5;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease
}
.landing-page-body .hero .benefits .gallery ul li img{
  width:256px;
  height:550px;
  object-fit:cover
}
.landing-page-body .hero .benefits .gallery ul.speed-1{
  transition:margin 350ms ease;
  -moz-transition:margin 350ms ease;
  -webkit-transition:margin 350ms ease
}
.landing-page-body .hero .benefits .gallery ul.speed-2{
  transition:margin 700ms ease;
  -moz-transition:margin 700ms ease;
  -webkit-transition:margin 700ms ease
}
.landing-page-body .hero .benefits .gallery ul.speed-3{
  transition:margin 1050ms ease;
  -moz-transition:margin 1050ms ease;
  -webkit-transition:margin 1050ms ease
}
.landing-page-body .hero .benefits .gallery ul.speed-4{
  transition:margin 1400ms ease;
  -moz-transition:margin 1400ms ease;
  -webkit-transition:margin 1400ms ease
}
.landing-page-body .hero .benefits .gallery ul.speed-5{
  transition:margin 1750ms ease;
  -moz-transition:margin 1750ms ease;
  -webkit-transition:margin 1750ms ease
}
.landing-page-body .hero .benefits .gallery ul.featured-1{
  margin:0
}
.landing-page-body .hero .benefits .gallery ul.featured-1 li.screen-1{
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1
}
.landing-page-body .hero .benefits .gallery ul.featured-2{
  margin:0 0 0 -256px
}
.landing-page-body .hero .benefits .gallery ul.featured-2 li.screen-2{
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1
}
.landing-page-body .hero .benefits .gallery ul.featured-3{
  margin:0 0 0 -512px
}
.landing-page-body .hero .benefits .gallery ul.featured-3 li.screen-3{
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1
}
.landing-page-body .hero .benefits .gallery ul.featured-4{
  margin:0 0 0 -768px
}
.landing-page-body .hero .benefits .gallery ul.featured-4 li.screen-4{
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1
}
.landing-page-body .hero .benefits .gallery ul.featured-5{
  margin:0 0 0 -1024px
}
.landing-page-body .hero .benefits .gallery ul.featured-5 li.screen-5{
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1
}
.landing-page-body .hero .benefits .gallery ul.featured-6{
  margin:0 0 0 -1280px
}
.landing-page-body .hero .benefits .gallery ul.featured-6 li.screen-6{
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1
}
.landing-page-body .hero .benefits:after{
  position:absolute;
  top:-27px;
  left:50%;
  width:320px;
  height:605px;
  content:'';
  background:url(../../../../assets/images/resellerLandingPage/iphone-full.png) 50% 50% no-repeat;
  background-size:cover;
  margin:0 0 0 -160px;
  z-index:10;
  pointer-events:none
}
.landing-page-body .hero .more{
  position:relative;
  display:inline-block;
  padding:10px 15px 25px;
  font-weight:700;
  font-size:10px;
  line-height:15px;
  text-transform:uppercase;
  letter-spacing:0.04em;
  color:#b3c5bc;
  margin:auto;
  background:url(../../../../assets/images/resellerLandingPage/arrow.svg) 50% 100% no-repeat;
  background-size:auto 16px;
  z-index:20;
  animation:more 750ms ease infinite;
  -moz-animation:more 750ms ease infinite;
  -webkit-animation:more 750ms ease infinite
}
@keyframes more{
  0%{
    background-position-y:100%
  }
  50%{
    background-position-y:80%
  }
  100%{
    background-position-y:100%
  }
}
@-moz-keyframes more{
  0%{
    background-position-y:100%
  }
  50%{
    background-position-y:80%
  }
  100%{
    background-position-y:100%
  }
}
@-webkit-keyframes more{
  0%{
    background-position-y:100%
  }
  50%{
    background-position-y:80%
  }
  100%{
    background-position-y:100%
  }
}

.landing-page-body .hero .background{
  position:absolute;
  top:0;
  left:0;
  display:block;
  width:100%;
  height:100%;
  content:'';
  background:url(../../../../assets/images/resellerLandingPage/background.jpg) 50% 50% no-repeat;
  background-size:cover;
}
.landing-page-body .block{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  width:100%;
  padding:30px 20px;
  overflow-x:hidden;
  margin:70px auto
}
.landing-page-body .block .video,.block .banner,.block .phones,.block .pdf,.block .screengrab{
  width:calc(50% - 15px);
  width:-moz-calc(50% - 15px);
  width:-webkit-calc(50% - 15px);
  max-width:560px
}
.landing-page-body .block .video a{
  position:relative;
  display:block;
  width:100%;
  height:0;
  border-radius:4px;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;
  padding:56.25% 0 0;
  overflow:hidden;
  background:url(../../../../assets/images/resellerLandingPage/video.jpg) 50% 50% no-repeat;
  background-size:cover;
  box-shadow:0 0 0 0 transparent;
  -moz-box-shadow:0 0 0 0 transparent;
  -webkit-box-shadow:0 0 0 0 transparent
}
.landing-page-body .block .video a span{
  position:absolute;
  left:0;
  bottom:0;
  width:100%;
  padding:16px 160px 14px 24px;
  font-weight:700;
  font-size:16px;
  line-height:25px;
  white-space:nowrap;
  text-align:left;
  color:#fff;
  overflow:hidden;
  text-overflow:ellipsis;
  background:rgba(0,0,0,0.5)
}
.landing-page-body .block .video a span:after{
  position:absolute;
  top:16px;
  right:24px;
  font-size:12px;
  text-transform:uppercase;
  content:'Click to watch';
  color:#76b83f
}
.landing-page-body .block .video a .svg{
  position:absolute;
  top:50%;
  left:50%;
  width:80px;
  height:80px;
  margin:-45px 0 0 -40px
}
.landing-page-body .block .video a .svg *{
  fill:#fff
}
.landing-page-body .block .video a:hover .svg *{
  fill:#b2e155
}
.landing-page-body .block .banner a{
  display:block;
  width:100%;
  border-radius:4px;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;
  overflow:hidden
}
.landing-page-body .block .banner .banner-shadow{
  box-shadow:0 2px 20px rgba(0,0,0,0.25);
  -moz-box-shadow:0 2px 20px rgba(0,0,0,0.25);
  -webkit-box-shadow:0 2px 20px rgba(0,0,0,0.25)
}
.landing-page-body .block .phones img{
  width:auto;
  max-height:380px
}
.landing-page-body .block .screengrab{
  position:relative
}
.landing-page-body .block .screengrab img{
  width:256px;
  height:398px;
  object-fit:cover
}
.landing-page-body .block .screengrab:after{
  position:absolute;
  top:-27px;
  left:50%;
  display:block;
  width:500px;
  height:425px;
  content:'';
  background:url(../../../../assets/images/resellerLandingPage/iphone-cropped.png) 50% 50% no-repeat;
  background-size:cover;
  z-index:10;
  pointer-events:none
}
.landing-page-body .block .text{
  width:calc(50% + 15px);
  width:-moz-calc(50% + 15px);
  width:-webkit-calc(50% + 15px);
  max-width:560px;
  text-align:left
}
.landing-page-body .block .text h2{
  font-weight:700;
  font-size:32px;
  line-height:40px;
  color:#fff;
  margin:0 0 30px
}
.landing-page-body .block .text p{
  margin:0 0 10px
}
.landing-page-body .block .text p strong{
  font-weight:700;
  color:#fff
}
.landing-page-body .block .text .button{
  margin:40px 0 0
}
.landing-page-body .block .text.full{
  width:100%;
  max-width:100%;
  margin:60px 0 0
}
.landing-page-body .block .text.full blockquote{
  max-width:790px;
  text-align:center;
  margin:100px auto 0
}
.landing-page-body .block .text.full blockquote p{
  font-size:16px;
  line-height:32px;
  color:#fff;
  margin:0 0 20px
}
.landing-page-body .block .text.full blockquote p:last-of-type{
  margin:0
}
.landing-page-body .block .text.full blockquote footer{
  display:inline-flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:left;
  margin:30px auto 0
}
.landing-page-body .block .text.full blockquote footer p{
  font-size:16px;
  line-height:24px;
  letter-spacing:0
}
.landing-page-body .block .text.full blockquote footer p span{
  display:block;
  color:#d1e4a5
}
.landing-page-body .block.align-center{
  padding-top:20px;
  align-items:center
}
.landing-page-body .block.text-right .video,.block.text-right .banner,.block.text-right .phones,.block.text-right .pdf,.block.text-right .screengrab{
  order:1
}
.landing-page-body .block.text-right .screengrab{
  padding:0 0 0 30px
}
.landing-page-body .block.text-right .screengrab:after{
  margin:0 0 0 -235px
}
.landing-page-body .block.text-right .text{
  order:2;
  padding:0 0 0 35px
}
.landing-page-body .block.text-right .text.full{
  order:3;
  padding:0
}
.landing-page-body .block.text-left .video,.block.text-left .banner,.block.text-left .phones,.block.text-left .pdf,.block.text-left .screengrab{
  order:2
}
.landing-page-body .block.text-left .screengrab{
  padding:0 30px 0 0
}
.landing-page-body .block.text-left .screengrab:after{
  margin:0 0 0 -265px
}
.landing-page-body .block.text-left .text{
  order:1;
  padding:0 35px 0 0
}
.landing-page-body .block.text-left .text.full{
  order:3;
  padding:0
}
.landing-page-body .grid{
  padding:0 50px;
  margin:100px auto
}
.landing-page-body .grid h2{
  font-weight:700;
  font-size:40px;
  line-height:44px;
  color:#fff;
  margin:0 0 50px
}
.landing-page-body .grid h2 .green{
  color:#76b83f;
  white-space:nowrap
}
.landing-page-body .grid p{
  font-size:16px
}
.landing-page-body .grid p.source{
  font-size:13px;
  line-height:18px;
  opacity:.5;
  -moz-opacity:.5;
  -webkit-opacity:.5
}
.landing-page-body .grid .intro p{
  margin:0 auto 20px
}
.landing-page-body .grid .intro p.bold{
  font-weight:700;
  font-size:20px;
  line-height:30px;
  color:#fff
}
.landing-page-body .grid ul{
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  margin:60px -50px 20px
}
.landing-page-body .grid ul li{
  width:calc(33.33333% - 70px);
  width:-moz-calc(33.33333% - 70px);
  width:-webkit-calc(33.33333% - 70px);
  margin:0 35px 50px
}
.landing-page-body .grid ul li img{
  width:100%;
  max-width:350px
}
.landing-page-body .grid ul li h3{
  font-weight:700;
  color:#fff;
  margin:10px auto
}
.landing-page-body .grid ul li p{
  margin:0 auto
}
.landing-page-body .grid .button{
  margin:auto
}
.landing-page-body .testimonial{
  padding:0 45px;
  text-align:center;
  margin:100px auto
}
.landing-page-body .testimonial h2{
  font-weight:700;
  font-size:50px;
  line-height:54px;
  color:#fff;
  margin:0 0 40px
}
.landing-page-body .testimonial blockquote{
  max-width:790px;
  margin:auto
}
.landing-page-body .testimonial blockquote p{
  font-size:16px;
  line-height:32px;
  color:#fff;
  margin:0 0 20px
}
.landing-page-body .testimonial blockquote p:first-of-type:before{
  display:inline-block;
  width:35px;
  height:18px;
  font:700 50px/45px 'Times New Roman', Times, serif;
  content:'\201C';
  text-align:center;
  color:#527a65;
  vertical-align:top;
  margin:0 8px 0 0
}
.landing-page-body .testimonial blockquote p:last-of-type{
  margin:0
}
.landing-page-body .testimonial blockquote p:last-of-type:after{
  display:inline-block;
  width:35px;
  height:18px;
  font:700 50px/50px 'Times New Roman', Times, serif;
  content:'\201D';
  text-align:center;
  vertical-align:top;
  color:#527a65;
  margin:0 0 0 8px
}
.landing-page-body .testimonial blockquote .rating{
  display:block;
  width:80px;
  height:16px;
  margin:18px auto 0;
  background:url(../../../../assets/images/resellerLandingPage/star.svg) 50% 50% repeat-x;
  background-size:16px 16px
}
.landing-page-body .testimonial blockquote footer{
  display:inline-flex;
  flex-wrap:wrap;
  align-items:center;
  text-align:left;
  justify-content:left;
  margin:30px auto 0
}
.landing-page-body .testimonial blockquote footer img{
  width:64px;
  height:64px;
  border-radius:10px;
  -moz-border-radius:10px;
  -webkit-border-radius:10px;
  margin:0 15px 0 0;
  box-shadow:0 4px 8px rgba(0,0,0,0.1);
  -moz-box-shadow:0 4px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow:0 4px 8px rgba(0,0,0,0.1)
}
.landing-page-body .testimonial blockquote footer p{
  font-size:16px;
  line-height:24px;
  letter-spacing:0
}
.landing-page-body .testimonial blockquote footer p span{
  display:block;
  color:#d1e4a5
}
.landing-page-body .testimonial blockquote footer p:first-of-type:before{
  display:none
}
.landing-page-body .testimonial blockquote footer p:last-of-type:after{
  display:none
}
.landing-page-body .testimonial blockquote footer.no-photo{
  text-align:center
}
.landing-page-body .world-land-trust{
  padding:0 45px;
  margin:75px auto 60px
}
.landing-page-body .world-land-trust .intro .logo{
  width:290px;
  /*height:140px*/
}
.landing-page-body .world-land-trust .intro h2{
  font-weight:700;
  font-size:40px;
  line-height:44px;
  letter-spacing:0.02em;
  color:#fff;
  margin:36px auto
}
.landing-page-body .world-land-trust .intro p{
  max-width:850px;
  font-size:16px;
  line-height:30px;
  margin:0 auto 40px
}
.landing-page-body .world-land-trust blockquote{
  position:relative;
  max-width:790px;
  padding:0 55px;
  margin:0 auto
}
.landing-page-body .world-land-trust blockquote p{
  font-weight:700;
  font-size:20px;
  line-height:29px;
  letter-spacing:-0.01em;
  color:#fff
}
.landing-page-body .world-land-trust blockquote p.green{
  color:#b3c5bc
}
.landing-page-body .world-land-trust blockquote footer{
  margin:35px auto 0
}
.landing-page-body .world-land-trust blockquote footer img{
  width:156px;
  height:156px;
  border-radius:999px;
  -moz-border-radius:999px;
  -webkit-border-radius:999px;
  object-fit:cover;
  margin:0 auto 20px;
  background:#fff;
  box-shadow:0 0 0 2px #fff;
  -moz-box-shadow:0 0 0 2px #fff;
  -webkit-box-shadow:0 0 0 2px #fff
}
.landing-page-body .world-land-trust blockquote footer p{
  font-weight:200;
  font-size:18px;
  line-height:26px;
  letter-spacing:0;
  color:#fff
}
.landing-page-body .world-land-trust blockquote footer p span{
  display:block;
  font-weight:400;
  font-size:20px;
  color:#d1e4a5
}
.landing-page-body .world-land-trust blockquote:before,.world-land-trust blockquote:after{
  position:absolute;
  top:-40px;
  font:400 128px/128px 'Times New Roman', Times, serif;
  color:#fff
}
.landing-page-body .world-land-trust blockquote:before{
  left:0;
  content:'\201C'
}
.landing-page-body .world-land-trust blockquote:after{
  right:0;
  content:'\201D'
}
.landing-page-body .registration{
  padding:0 50px;
  margin:100px auto
}
.landing-page-body .registration h2{
  font-weight:700;
  font-size:40px;
  line-height:44px;
  color:#fff;
  margin:0 0 50px
}
.landing-page-body .registration h2 .green{
  color:#76b83f
}
.landing-page-body .registration p{
  font-size:16px;
  margin:0 auto 10px
}
.landing-page-body .registration p strong{
  font-weight:700;
  font-size:18px;
  line-height:28px;
  color:#fff
}
.landing-page-body .registration p.disclaimer{
  max-width:520px;
  font-size:13px;
  line-height:18px;
  opacity:.5;
  -moz-opacity:.5;
  -webkit-opacity:.5
}
.landing-page-body .registration .form{
  position:relative;
  display:flex;
  flex-wrap:wrap;
  max-width:1000px;
  margin:50px auto 0
}
.landing-page-body .registration .form .field{
  position:relative;
  width:calc(50% - 5px);
  width:-moz-calc(50% - 5px);
  width:-webkit-calc(50% - 5px);
  text-align:left
}
.landing-page-body .registration .form .field label{
  position:absolute;
  top:16px;
  left:14px;
  color:#fff;
  pointer-events:none;
  transition:all 250ms ease;
  -moz-transition:all 250ms ease;
  -webkit-transition:all 250ms ease
}
.landing-page-body .registration .form .field input[type="text"],.registration .form .field input[type="email"],.registration .form .field input[type="tel"]{
  width:100%;
  height:58px;
  border:none;
  border-bottom:1px #527b66 solid;
  padding:25px 14px 4px;
  font:inherit;
  color:#fff;
  background:#1b5136;
  transition:all 250ms ease;
  -moz-transition:all 250ms ease;
  -webkit-transition:all 250ms ease
}
.landing-page-body .registration .form .field input[type="text"]:focus,.registration .form .field input[type="email"]:focus,.registration .form .field input[type="tel"]:focus{
  border-color:#adc1b7
}
.landing-page-body .registration .form .field .wpcf7-form-control-wrap{
  position:static
}
.landing-page-body .registration .form .field .wpcf7-not-valid-tip{
  position:absolute;
  top:4px;
  right:14px;
  font-size:12px;
  color:#fff;
  pointer-events:none;
  opacity:.5;
  -moz-opacity:.5;
  -webkit-opacity:.5
}
.landing-page-body .registration .form .field:nth-of-type(odd){
  margin:0 5px 10px 0
}
.landing-page-body .registration .form .field:nth-of-type(even){
  margin:0 0 10px 5px
}
.landing-page-body .registration .form .field.completed label{
  font-size:12px;
  top:4px;
  opacity:.5;
  -moz-opacity:.5;
  -webkit-opacity:.5
}
.landing-page-body .registration .form input[type="submit"]{
  display:inline-flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  min-width:250px;
  max-width:100%;
  height:50px;
  border-radius:999px;
  -moz-border-radius:999px;
  -webkit-border-radius:999px;
  padding:12px 40px 8px;
  font:inherit;
  font-weight:700;
  font-size:14px;
  line-height:18px;
  text-transform:uppercase;
  text-align:center;
  letter-spacing:0.025em;
  color:#fff;
  margin:50px auto;
  background:#76b83f;
  box-shadow:0 10px 20px rgba(0,0,0,0.25);
  -moz-box-shadow:0 10px 20px rgba(0,0,0,0.25);
  -webkit-box-shadow:0 10px 20px rgba(0,0,0,0.25)
}
.landing-page-body .registration .form input[type="submit"]::selection{
  background:transparent
}
.landing-page-body .registration .form input[type="submit"]::-moz-selection{
  background:transparent
}
.landing-page-body .registration .form input[type="submit"]:hover{
  color:#012814;
  background:#b2e155
}
.landing-page-body .registration .form .ajax-loader{
  position:absolute;
  top:0;
  left:-25px;
  right:-25px;
  bottom:0;
  display:block;
  width:100%;
  width:calc(100% + 50px);
  width:-moz-calc(100% + 50px);
  width:-webkit-calc(100% + 50px);
  height:100%;
  overflow:hidden;
  margin:0;
  background:rgba(1,61,31,0.75);
  z-index:10000000000
}
.landing-page-body .registration .form .ajax-loader:after{
  position:absolute;
  top:27.5%;
  left:50%;
  display:block;
  width:30px;
  height:30px;
  content:'';
  margin:-15px 0 0 -15px;
  background:url(../../../../assets/images/resellerLandingPage/reseller-invite/animated/spinner.png) 50% 50% no-repeat;
  background-size:30px 30px;
  z-index:10
}
.landing-page-body .registration .form .wpcf7-response-output,.registration .form .wpcf7-validation-errors,.registration .form .wpcf7-mail-sent-ok,.registration .form .wpcf7-mail-sent-ng{
  width:100%;
  font-weight:700;
  font-size:18px;
  line-height:28px;
  color:#fff;
  margin:0 0 40px
}
.landing-page-body .registration .sent .field,.registration .sent .submit{
  display:none
}
.landing-page-body .copyright{
  margin:105px auto 125px
}
.landing-page-body .copyright img{
  width:24px;
  height:24px;
  margin:0 auto 12px
}
.landing-page-body .copyright p{
  font-size:10px;
  line-height:16px;
  color:#fff
}
.landing-page-body .lightbox{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background:rgba(0,0,0,0.4);
  z-index:2000;
  visibility:hidden;
  opacity:0;
  -moz-opacity:0;
  -webkit-opacity:0;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  z-index:1000
}
.landing-page-body .lightbox .placeholder{
  position:absolute;
  width:1230px;
  max-width:calc(100% - 70px);
  max-width:-moz-calc(100% - 70px);
  max-width:-webkit-calc(100% - 70px);
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  -moz-transform:translate(-50%, -50%);
  -webkit-transform:translate(-50%, -50%)
}
.landing-page-body .lightbox .placeholder .modal{
  position:relative;
  padding:56.25% 0 0 0;
  background:#000;
  box-shadow:0 5px 20px rgba(0,0,0,0.75);
  -moz-box-shadow:0 5px 20px rgba(0,0,0,0.75);
  -webkit-box-shadow:0 5px 20px rgba(0,0,0,0.75);
  opacity:0;
  -moz-opacity:0;
  -webkit-opacity:0;
  transform:scale(0);
  -moz-transform:scale(0);
  -webkit-transform:scale(0);
  transition:all 750ms ease;
  -moz-transition:all 750ms ease;
  -webkit-transition:all 750ms ease;
  transition-delay:250ms;
  -moz-transition-delay:250ms;
  -webkit-transition-delay:250ms
}
.landing-page-body .lightbox .placeholder .modal iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%
}
.landing-page-body .lightbox .close{
  position:absolute;
  top:20px;
  right:15px;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:center;
  width:75px;
  height:35px;
  border-radius:999px;
  -moz-border-radius:999px;
  -webkit-border-radius:999px;
  padding:10px 15px 8px;
  font-weight:700;
  font-size:10px;
  line-height:15px;
  text-align:center;
  text-transform:uppercase;
  text-decoration:none;
  letter-spacing:0.025em;
  color:#fff;
  background:#76b83f
}
.landing-page-body .lightbox .close:hover{
  color:#012814;
  background:#b2e155
}
.landing-page-body .lightbox.open{
  visibility:visible;
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1
}
.landing-page-body .lightbox.open .placeholder .modal{
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1;
  transform:scale(1);
  -moz-transform:scale(1);
  -webkit-transform:scale(1)
}
@media (max-width: 1100px){
  body .landing-page-body {
    font-size:16px;
    line-height:25px
  }
  .landing-page-body .hero{
    margin:0 0 80px
  }
  .landing-page-body .hero .header h1{
    font-size:35px;
    line-height:42px
  }
  .landing-page-body .hero .header p{
    margin:15px auto
  }
  .landing-page-body .hero .header .button{
    margin:20px auto 10px
  }
  .landing-page-body .hero .benefits .info{
    padding:0 20px 60px;
  }
  .landing-page-body .hero .benefits .info * {
    cursor: default;
  }
  .landing-page-body .hero .benefits .info li .padding {
    cursor: default;
  }
  .landing-page-body .hero .benefits .info li .padding h2{
    font-size:16px;
    line-height:20px;
    margin:0 0 4px
  }
  .landing-page-body .hero .benefits .info li .padding p{
    line-height:18px
  }
  .landing-page-body .hero .more{
    margin:15px auto
  }
  .landing-page-body .block{
    margin:50px auto
  }
  .landing-page-body .block .screengrab img{
    width:234px;
    height:363px
  }
  .landing-page-body .block .screengrab:after{
    top:-25px;
    width:456px;
    height:388px
  }
  .landing-page-body .block .text h2{
    font-size:28px;
    line-height:38px
  }
  .landing-page-body .block .text.full{
    margin:50px 0 0
  }
  .landing-page-body .block.text-right .screengrab{
    padding:0 0 0 20px
  }
  .landing-page-body .block.text-right .screengrab:after{
    margin:0 0 0 -218px
  }
  .landing-page-body .block.text-left .screengrab{
    padding:0 20px 0 0
  }
  .landing-page-body .block.text-left .screengrab:after{
    margin:0 0 0 -238px
  }
  .landing-page-body .grid{
    padding:0 50px;
    margin:75px auto
  }
  .landing-page-body .grid h2{
    font-size:36px;
    line-height:40px
  }
  .landing-page-body .grid ul{
    margin:40px -20px 10px
  }
  .landing-page-body .testimonial{
    padding:0 60px;
    margin:75px auto
  }
  .landing-page-body .copyright{
    margin:85px auto 95px
  }
}
@media (max-width: 890px){
  .landing-page-body .hero{
    min-height:1024px;
    margin:0 0 50px
  }
  .landing-page-body .hero .header h1{
    max-width:500px
  }
  .landing-page-body .hero .header p{
    margin:20px auto 35px
  }
  .landing-page-body .hero .header .button{
    margin:25px auto 0
  }
  .landing-page-body .hero .benefits .info{
    display:block;
    padding:0 340px 0 0;
    margin:-8px 0 0;
  }
  .landing-page-body .hero .benefits .info li{
    width:auto;
  }
  .landing-page-body .hero .benefits .info li .padding .icon{
    display:none;
  }
  .landing-page-body .hero .benefits .info li .padding p{
    font-size:12px;
    line-height:15px;
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd),.hero .benefits .info li:nth-of-type(even){
    padding:0 !important;
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding,.hero .benefits .info li:nth-of-type(even) .padding{
    padding:18px 25px !important;
    text-align:left !important;
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .line,.hero .benefits .info li:nth-of-type(even) .padding .line{
    left:25px;
    right:auto;
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .line .dot,.hero .benefits .info li:nth-of-type(even) .padding .line .dot{
    left:auto;
    right:-12px;
  }
  .landing-page-body .hero .benefits .info li.active .padding .line{
    width:100%;
  }
  .landing-page-body .hero .benefits .gallery{
    left:auto;
    right:60px;
  }
  .landing-page-body .hero .benefits:after{
    left:auto;
    right:28px;
  }
  .landing-page-body .block{
    display:block;
    max-width:720px;
    margin:35px auto
  }
  .landing-page-body .block .video,.block .banner,.block .phones,.block .pdf,.block .screengrab{
    width:auto;
    max-width:480px;
    margin:0 auto 50px
  }
  .landing-page-body .block .phones img{
    max-height:320px
  }
  .landing-page-body .block .text{
    width:auto;
    max-width:100%;
    text-align:center
  }
  .landing-page-body .block .text h2{
    max-width:520px;
    margin:0 auto 20px
  }
  .landing-page-body .block .text p{
    margin:0 auto 10px
  }
  .landing-page-body .block .text .button{
    margin:30px auto 0
  }
  .landing-page-body .block .text.full{
    margin:0
  }
  .landing-page-body .block.text-right .screengrab,
  .landing-page-body .block.text-left .screengrab{
    padding:0
  }
  .landing-page-body .block.text-right .screengrab:after,
  .landing-page-body .block.text-left .screengrab:after{
    margin:0 0 0 -228px
  }
  .landing-page-body .block.text-right .text,
  .landing-page-body .block.text-left .text{
    padding:0 40px
  }
  .landing-page-body .grid{
    padding:0 30px;
    margin:60px auto
  }
  .landing-page-body .grid h2{
    font-size:30px;
    line-height:40px;
    margin:0 0 30px
  }
  .landing-page-body .grid .intro p strong{
    max-width:580px;
    font-size:18px;
    line-height:28px
  }
  .landing-page-body .grid ul{
    margin:40px -20px 0
  }
  .landing-page-body .grid ul li{
    width:calc(50% - 80px);
    width:-moz-calc(50% - 80px);
    width:-webkit-calc(50% - 80px);
    margin:0 40px 40px
  }
  .landing-page-body .grid ul li img{
    max-width:300px
  }
  .landing-page-body .grid ul li h3{
    font-size:18px;
    line-height:28px
  }
  .landing-page-body .grid ul li p{
    font-size:15px;
    line-height:24px
  }
  .landing-page-body .testimonial{
    padding:0 50px;
    text-align:center;
    margin:60px auto
  }
  .landing-page-body .testimonial h2{
    font-size:35px;
    line-height:42px;
    margin:0 auto 30px
  }
  .landing-page-body .testimonial blockquote{
    max-width:720px
  }
  .landing-page-body .testimonial blockquote p{
    font-size:15px;
    line-height:28px;
    letter-spacing:0
  }
  .landing-page-body .testimonial blockquote footer{
    display:inline-block;
    text-align:center
  }
  .landing-page-body .testimonial blockquote footer img{
    margin:0 auto 20px
  }
  .landing-page-body .world-land-trust{
    padding:0 50px;
    margin:60px auto
  }
  .landing-page-body .world-land-trust .intro .logo{
    width:240px;
    height:116px
  }
  .landing-page-body .world-land-trust .intro h2{
    font-size:28px;
    line-height:38px;
    letter-spacing:0;
    margin:32px auto
  }
  .landing-page-body .world-land-trust .intro p{
    font-size:14px;
    line-height:26px;
    margin:0 auto 32px
  }
  .landing-page-body .world-land-trust blockquote{
    padding:0 50px
  }
  .landing-page-body .world-land-trust blockquote p{
    font-size:18px;
    line-height:26px;
    letter-spacing:0
  }
  .landing-page-body .world-land-trust blockquote footer img{
    width:128px;
    height:128px
  }
  .landing-page-body .world-land-trust blockquote footer p{
    font-size:16px;
    line-height:24px
  }
  .landing-page-body .world-land-trust blockquote footer p span{
    font-weight:400;
    font-size:16px
  }
  .landing-page-body .world-land-trust blockquote:before,
  .landing-page-body .world-land-trust blockquote:after{
    top:-30px;
    font-size:110px;
    line-height:110px
  }
  .landing-page-body .registration{
    padding:0 30px;
    margin:60px auto
  }
  .landing-page-body .registration h2{
    font-size:30px;
    line-height:40px;
    margin:0 0 30px
  }
  .landing-page-body .registration .form{
    display:block;
    max-width:500px
  }
  .landing-page-body .registration .form .field{
    width:auto
  }
  .landing-page-body .registration .form .field:nth-of-type(odd),
  .landing-page-body .registration .form .field:nth-of-type(even){
    margin:0 0 10px
  }
  .landing-page-body .copyright{
    margin:50px auto 65px
  }
}
@media (max-width: 700px){
  body .landing-page-body {
    font-size:15px;
    line-height:24px
  }
  .landing-page-body .center{
    max-width:760px;
    padding:0 15px
  }
  .landing-page-body .hr{
    margin:40px auto
  }
  .landing-page-body .button{
    min-width:230px;
    height:45px;
    font-size:12px;
    line-height:15px;
    box-shadow:0 5px 10px rgba(0,0,0,0.15);
    -moz-box-shadow:0 5px 10px rgba(0,0,0,0.15);
    -webkit-box-shadow:0 5px 10px rgba(0,0,0,0.15)
  }
  .landing-page-body .hero{
    display:block;
    height:auto;
    min-height:0;
    padding:35px 0 30px;
    margin:0 0 40px
  }
  .landing-page-body .hero .header{
    padding:0 35px
  }
  .landing-page-body .hero .header h1{
    font-size:30px;
    line-height:40px
  }
  .landing-page-body .hero .header p{
    font-weight:400;
    font-size:14px;
    line-height:20px;
    margin:15px auto
  }
  .landing-page-body .hero .benefits{
    height:425px;
    margin:40px auto 0
  }
  .landing-page-body .hero .benefits .info{
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    height:auto;
    padding:0;
    margin:0;
    z-index:20
  }
  .landing-page-body .hero .benefits .info li{
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:120px;
    opacity:0;
    -moz-opacity:0;
    -webkit-opacity:0;
    transition:all 750ms ease;
    -moz-transition:all 750ms ease;
    -webkit-transition:all 750ms ease
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd),
  .landing-page-body .hero .benefits .info li:nth-of-type(even){
    padding:0 !important
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding,
  .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding{
    padding:0;
    margin:28px 25px 0;
    text-align:center !important;
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding h2,
  .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding h2{
    display:-webkit-box;
    max-height:20px;
    margin:0 auto 8px;
    overflow:hidden;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding p,
  .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding p{
    display:-webkit-box;
    max-height:60px;
    max-width:300px;
    font-size:14px;
    line-height:20px;
    margin:auto;
    overflow:hidden;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical
  }
  .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .line,
  .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding .line{
    display:none
  }
  .landing-page-body .hero .benefits .info li.active{
    opacity:1;
    -moz-opacity:1;
    -webkit-opacity:1
  }
  .landing-page-body .hero .benefits .gallery{
    left:50%;
    right:auto;
    width:190px;
    height:295px;
    margin:0 0 0 -95px
  }
  .landing-page-body .hero .benefits .gallery ul{
    width:1140px
  }
  .landing-page-body .hero .benefits .gallery ul li img{
    width:190px;
    height:408px
  }
  .landing-page-body .hero .benefits .gallery ul.featured-2{
    margin:0 0 0 -190px
  }
  .landing-page-body .hero .benefits .gallery ul.featured-3{
    margin:0 0 0 -380px
  }
  .landing-page-body .hero .benefits .gallery ul.featured-4{
    margin:0 0 0 -570px
  }
  .landing-page-body .hero .benefits .gallery ul.featured-5{
    margin:0 0 0 -760px
  }
  .landing-page-body .hero .benefits .gallery ul.featured-6{
    margin:0 0 0 -950px
  }
  .landing-page-body .hero .benefits:after{
    top:-20px;
    left:50%;
    right:auto;
    width:370px;
    height:315px;
    margin:0 0 0 -185px;
    background-image:url(../../../../assets/images/resellerLandingPage/iphone-cropped.png)
  }
  .landing-page-body .hero .more{
    display:none
  }
  .landing-page-body .block{
    padding:0 0 50px;
    margin:0 auto
  }
  .landing-page-body .block .video,
  .landing-page-body .block .banner,
  .landing-page-body .block .phones,
  .landing-page-body .block .pdf,
  .landing-page-body .block .screengrab{
    max-width:350px;
    margin:0 auto 30px
  }
  .landing-page-body .block .phones img{
    max-height:265px
  }
  .landing-page-body .block .video a{
    position:relative;
    display:block;
    width:100%;
    height:0;
    border-radius:4px;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    padding:56.25% 0 0;
    overflow:hidden;
    background:url(../../../../assets/images/resellerLandingPage/video.jpg) 50% 50% no-repeat;
    background-size:cover;
    box-shadow:0 0 0 0 transparent;
    -moz-box-shadow:0 0 0 0 transparent;
    -webkit-box-shadow:0 0 0 0 transparent
  }
  .landing-page-body .block .video a span{
    padding:16px 20px 12px;
    font-size:14px;
    line-height:20px;
    text-align:center
  }
  .landing-page-body .block .video a span:after{
    display:none
  }
  .landing-page-body .block .video a .svg{
    width:64px;
    height:64px;
    margin:-44px 0 0 -32px
  }
  .landing-page-body .block .screengrab img{
    width:190px;
    height:295px
  }
  .landing-page-body .block .screengrab:after{
    top:-20px;
    width:370px;
    height:315px
  }
  .landing-page-body .block .text h2{
    font-size:20px;
    line-height:28px;
    margin:0 auto 15px
  }
  .landing-page-body .block .text p{
    margin:0 auto 10px
  }
  .landing-page-body .block .text .button{
    margin:30px auto 0
  }
  .landing-page-body .block.text-right .screengrab:after,
  .landing-page-body .block.text-left .screengrab:after{
    margin:0 0 0 -185px
  }
  .landing-page-body .block.text-right .screengrab,
  .landing-page-body .block.text-left .screengrab{
    padding:0
  }
  .landing-page-body .block.text-right .text,
  .landing-page-body .block.text-left .text{
    padding:0 15px
  }
  .landing-page-body .grid{
    padding:0 20px;
    margin:45px auto
  }
  .landing-page-body .grid h2{
    font-size:26px;
    line-height:35px
  }
  .landing-page-body .grid .intro p{
    font-size:15px;
    line-height:24px
  }
  .landing-page-body .grid .intro p strong{
    font-size:16px;
    line-height:25px
  }
  .landing-page-body .grid ul{
    display:block;
    margin:40px 0
  }
  .landing-page-body .grid ul li{
    width:auto;
    margin:0 0 20px
  }
  .landing-page-body .grid ul li img{
    max-width:250px
  }
  .landing-page-body .grid ul li:last-of-type{
    width:auto
  }
  .landing-page-body .testimonial{
    padding:0 20px;
    margin:45px auto
  }
  .landing-page-body .testimonial h2{
    font-size:28px;
    line-height:38px;
    margin:0 auto 25px
  }
  .landing-page-body .world-land-trust{
    padding:0 20px;
    margin:45px auto
  }
  .landing-page-body .world-land-trust .intro .logo{
    width:198px;
    height:96px
  }
  .landing-page-body .world-land-trust .intro h2{
    font-size:20px;
    line-height:28px;
    margin:20px auto 15px
  }
  .landing-page-body .world-land-trust .intro p{
    font-size:13px;
    line-height:22px;
    margin:0 auto 25px
  }
  .landing-page-body .world-land-trust blockquote{
    padding:0 25px
  }
  .landing-page-body .world-land-trust blockquote:before,
  .landing-page-body .world-land-trust blockquote:after{
    top:-10px;
    font-size:70px;
    line-height:70px
  }
  .landing-page-body .world-land-trust blockquote:before{
    left:-12px
  }
  .landing-page-body .world-land-trust blockquote:after{
    right:-12px
  }
  .landing-page-body .registration{
    padding:0 20px;
    margin:45px auto
  }
  .landing-page-body .registration h2{
    font-size:24px;
    line-height:32px
  }
  .landing-page-body .registration p{
    font-size:15px
  }
  .landing-page-body .registration .form{
    max-width:460px;
    margin:35px auto 0
  }
  .landing-page-body .registration .form .field label{
    top:18px
  }
  .landing-page-body .registration .form .field:nth-of-type(odd),
  .landing-page-body .registration .form .field:nth-of-type(even){
    margin:0 -20px 10px
  }
  .landing-page-body .registration .form input[type="submit"]{
    min-width:230px;
    height:45px;
    font-size:12px;
    line-height:15px;
    box-shadow:0 5px 10px rgba(0,0,0,0.15);
    -moz-box-shadow:0 5px 10px rgba(0,0,0,0.15);
    -webkit-box-shadow:0 5px 10px rgba(0,0,0,0.15)
  }
  .landing-page-body .copyright{
    margin:30px auto 40px
  }
}

/* preview styles */

.LandingPage.preview::-webkit-scrollbar {
  width: 0 !important;
}

.LandingPage.preview {
  overflow: auto !important;
  scrollbar-width: 'none';
}

.preview a, .preview button {
  pointer-events: none;
}

.preview.mobile.editor .mobilePreviewScrollPadding {
  height: 50px;
  width: auto;
  background: black;
}

/* preview desktop */


.preview.desktop .landing-page-body .hero .background {
  background-repeat: repeat-x;
  width: 150%;
  height: 150%;
  top: -50%;
}

/* preview tablet */

/* 1100px & below*/
body .preview.tablet .landing-page-body {
  font-size:16px;
  line-height:25px
}
.preview.tablet .landing-page-body .hero{
  margin:0 0 80px
}
.preview.tablet .landing-page-body .hero .header h1{
  font-size:35px;
  line-height:42px
}
.preview.tablet .landing-page-body .hero .header p{
  margin:15px auto
}
.preview.tablet .landing-page-body .hero .header .button{
  margin:20px auto 10px
}
.preview.tablet .landing-page-body .hero .benefits .info{
  padding:0 20px 60px;
}
.preview.tablet .landing-page-body .hero .benefits .info * {
  cursor: default;
}
.preview.tablet .landing-page-body .hero .benefits .info li .padding {
  cursor: default;
}
.preview.tablet .landing-page-body .hero .benefits .info li .padding h2{
  font-size:16px;
  line-height:20px;
  margin:0 0 4px
}
.preview.tablet .landing-page-body .hero .benefits .info li .padding p{
  line-height:18px
}
.preview.tablet .landing-page-body .hero .more{
  margin:15px auto
}
.preview.tablet .landing-page-body .block{
  margin:50px auto
}
.preview.tablet .landing-page-body .block .screengrab img{
  width:234px;
  height:363px
}
.preview.tablet .landing-page-body .block .screengrab:after{
  top:-25px;
  width:456px;
  height:388px
}
.preview.tablet .landing-page-body .block .text h2{
  font-size:28px;
  line-height:38px
}
.preview.tablet .landing-page-body .block .text.full{
  margin:50px 0 0
}
.preview.tablet .landing-page-body .block.text-right .screengrab{
  padding:0 0 0 20px
}
.preview.tablet .landing-page-body .block.text-right .screengrab:after{
  margin:0 0 0 -218px
}
.preview.tablet .landing-page-body .block.text-left .screengrab{
  padding:0 20px 0 0
}
.preview.tablet .landing-page-body .block.text-left .screengrab:after{
  margin:0 0 0 -238px
}
.preview.tablet .landing-page-body .grid{
  padding:0 50px;
  margin:75px auto
}
.preview.tablet .landing-page-body .grid h2{
  font-size:36px;
  line-height:40px
}
.preview.tablet .landing-page-body .grid ul{
  margin:40px -20px 10px
}
.preview.tablet .landing-page-body .testimonial{
  padding:0 60px;
  margin:75px auto
}
.preview.tablet .landing-page-body .copyright{
  margin:85px auto 95px
}

/* 890px & below*/

.preview.tablet .landing-page-body .hero{
  min-height:1024px;
  margin:0 0 50px
}
.preview.tablet .landing-page-body .hero .header h1{
  max-width:500px
}
.preview.tablet .landing-page-body .hero .header p{
  margin:20px auto 35px
}
.preview.tablet .landing-page-body .hero .header .button{
  margin:25px auto 0
}
.preview.tablet .landing-page-body .hero .benefits .info{
  display:block;
  padding:0 340px 0 0;
  margin:-8px 0 0;
}
.preview.tablet .landing-page-body .hero .benefits .info li{
  width:auto;
}
.preview.tablet .landing-page-body .hero .benefits .info li .padding .icon{
  display:none;
}
.preview.tablet .landing-page-body .hero .benefits .info li .padding p{
  font-size:12px;
  line-height:15px;
}
.preview.tablet .landing-page-body .hero .benefits .info li:nth-of-type(odd),
.preview.tablet .landing-page-body .hero .benefits .info li:nth-of-type(even){
  padding:0 !important;
}
.preview.tablet .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding,
.preview.tablet .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding{
  padding:18px 25px !important;
  text-align:left !important;
}
.preview.tablet .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .line,
.preview.tablet .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding .line{
  left:25px;
  right:auto;
}
.preview.tablet .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .line .dot,
.preview.tablet .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding .line .dot{
  left:auto;
  right:-12px;
}
.preview.tablet .landing-page-body .hero .benefits .info li.active .padding .line{
  width:100%;
}
.preview.tablet .landing-page-body .hero .benefits .gallery{
  left:auto;
  right:60px;
}
.preview.tablet .landing-page-body .hero .benefits:after{
  left:auto;
  right:28px;
}
.preview.tablet .landing-page-body .block{
  display:block;
  max-width:720px;
  margin:35px auto
}
.preview.tablet .landing-page-body .block .video,
.preview.tablet .landing-page-body .block .banner,
.preview.tablet .landing-page-body .block .phones,
.preview.tablet .landing-page-body .block .pdf,
.preview.tablet .landing-page-body .block .screengrab{
  width:auto;
  max-width:480px;
  margin:0 auto 50px
}
.preview.tablet .landing-page-body .block .phones img{
  max-height:320px
}
.preview.tablet .landing-page-body .block .text{
  width:auto;
  max-width:100%;
  text-align:center
}
.preview.tablet .landing-page-body .block .text h2{
  max-width:520px;
  margin:0 auto 20px
}
.preview.tablet .landing-page-body .block .text p{
  margin:0 auto 10px
}
.preview.tablet .landing-page-body .block .text .button{
  margin:30px auto 0
}
.preview.tablet .landing-page-body .block .text.full{
  margin:0
}
.preview.tablet .landing-page-body .block.text-right .screengrab,
.preview.tablet .landing-page-body .block.text-left .screengrab{
  padding:0
}
.preview.tablet .landing-page-body .block.text-right .screengrab:after,
.preview.tablet .landing-page-body .block.text-left .screengrab:after{
  margin:0 0 0 -228px
}
.preview.tablet .landing-page-body .block.text-right .text,
.preview.tablet .landing-page-body .block.text-left .text{
  padding:0 40px
}
.preview.tablet .landing-page-body .grid{
  padding:0 30px;
  margin:60px auto
}
.preview.tablet .landing-page-body .grid h2{
  font-size:30px;
  line-height:40px;
  margin:0 0 30px
}
.preview.tablet .landing-page-body .grid .intro p strong{
  max-width:580px;
  font-size:18px;
  line-height:28px
}
.preview.tablet .landing-page-body .grid ul{
  margin:40px -20px 0
}
.preview.tablet .landing-page-body .grid ul li{
  width:calc(50% - 80px);
  width:-moz-calc(50% - 80px);
  width:-webkit-calc(50% - 80px);
  margin:0 40px 40px
}
.preview.tablet .landing-page-body .grid ul li img{
  max-width:300px
}
.preview.tablet .landing-page-body .grid ul li h3{
  font-size:18px;
  line-height:28px
}
.preview.tablet .landing-page-body .grid ul li p{
  font-size:15px;
  line-height:24px
}
.preview.tablet .landing-page-body .testimonial{
  padding:0 50px;
  text-align:center;
  margin:60px auto
}
.preview.tablet .landing-page-body .testimonial h2{
  font-size:35px;
  line-height:42px;
  margin:0 auto 30px
}
.preview.tablet .landing-page-body .testimonial blockquote{
  max-width:720px
}
.preview.tablet .landing-page-body .testimonial blockquote p{
  font-size:15px;
  line-height:28px;
  letter-spacing:0
}
.preview.tablet .landing-page-body .testimonial blockquote footer{
  display:inline-block;
  text-align:center
}
.preview.tablet .landing-page-body .testimonial blockquote footer img{
  margin:0 auto 20px
}
.preview.tablet .landing-page-body .world-land-trust{
  padding:0 50px;
  margin:60px auto
}
.preview.tablet .landing-page-body .world-land-trust .intro .logo{
  width:240px;
  height:116px
}
.preview.tablet .landing-page-body .world-land-trust .intro h2{
  font-size:28px;
  line-height:38px;
  letter-spacing:0;
  margin:32px auto
}
.preview.tablet .landing-page-body .world-land-trust .intro p{
  font-size:14px;
  line-height:26px;
  margin:0 auto 32px
}
.preview.tablet .landing-page-body .world-land-trust blockquote{
  padding:0 50px
}
.preview.tablet .landing-page-body .world-land-trust blockquote p{
  font-size:18px;
  line-height:26px;
  letter-spacing:0
}
.preview.tablet .landing-page-body .world-land-trust blockquote footer img{
  width:128px;
  height:128px
}
.preview.tablet .landing-page-body .world-land-trust blockquote footer p{
  font-size:16px;
  line-height:24px
}
.preview.tablet .landing-page-body .world-land-trust blockquote footer p span{
  font-weight:400;
  font-size:16px
}
.preview.tablet .landing-page-body .world-land-trust blockquote:before,
.preview.tablet .landing-page-body .world-land-trust blockquote:after{
  top:-30px;
  font-size:110px;
  line-height:110px
}
.preview.tablet .landing-page-body .registration{
  padding:0 30px;
  margin:60px auto
}
.preview.tablet .landing-page-body .registration h2{
  font-size:30px;
  line-height:40px;
  margin:0 0 30px
}
.preview.tablet .landing-page-body .registration .form{
  display:block;
  max-width:500px
}
.preview.tablet .landing-page-body .registration .form .field{
  width:auto
}
.preview.tablet .landing-page-body .registration .form .field:nth-of-type(odd),
.preview.tablet .landing-page-body .registration .form .field:nth-of-type(even){
  margin:0 0 10px
}
.preview.tablet .landing-page-body .copyright{
  margin:50px auto 65px
}

/* preview mobile */

body .preview.mobile .landing-page-body {
  font-size:15px;
  line-height:24px
}
.preview.mobile .landing-page-body .center{
  max-width:760px;
  padding:0 15px
}
.preview.mobile .landing-page-body .button{
  min-width:230px;
  height:45px;
  font-size:12px;
  line-height:15px;
  box-shadow:0 5px 10px rgba(0,0,0,0.15);
  -moz-box-shadow:0 5px 10px rgba(0,0,0,0.15);
  -webkit-box-shadow:0 5px 10px rgba(0,0,0,0.15)
}
.preview.mobile .landing-page-body .hero{
  display:block;
  height:auto;
  min-height:0;
  padding:35px 0 30px;
  margin:0 0 40px
}
.preview.mobile .landing-page-body .hero .header{
  padding:0 35px
}
.preview.mobile .landing-page-body .hero .header h1{
  font-size:30px;
  line-height:40px
}
.preview.mobile .landing-page-body .hero .header p{
  font-weight:400;
  font-size:14px;
  line-height:20px;
  margin:15px auto
}
.preview.mobile .landing-page-body .hero .benefits{
  height:425px;
  margin:40px auto 0
}
.preview.mobile .landing-page-body .hero .benefits .info{
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  height:auto;
  padding:0;
  margin:0;
  z-index:20
}
.preview.mobile .landing-page-body .hero .benefits .info li{
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:120px;
  opacity:0;
  -moz-opacity:0;
  -webkit-opacity:0;
  transition:all 750ms ease;
  -moz-transition:all 750ms ease;
  -webkit-transition:all 750ms ease
}
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(odd),
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(even){
  padding:0 !important
}
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding,
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding{
  padding:0;
  margin:28px 25px 0;
  text-align:center !important;
}
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding h2,
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding h2{
  display:-webkit-box;
  max-height:20px;
  margin:0 auto 8px;
  overflow:hidden;
  -webkit-line-clamp:1;
  -webkit-box-orient:vertical
}
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding p,
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding p{
  display:-webkit-box;
  max-height:60px;
  max-width:300px;
  font-size:14px;
  line-height:20px;
  margin:auto;
  overflow:hidden;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical
}
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(odd) .padding .line,
.preview.mobile .landing-page-body .hero .benefits .info li:nth-of-type(even) .padding .line{
  display:none
}
.preview.mobile .landing-page-body .hero .benefits .info li.active{
  opacity:1;
  -moz-opacity:1;
  -webkit-opacity:1
}
.preview.mobile .landing-page-body .hero .benefits .info li .padding .icon{
  display:none;
}
.preview.mobile .landing-page-body .hero .benefits .gallery{
  left:50%;
  right:auto;
  width:190px;
  height:295px;
  margin:0 0 0 -95px
}
.preview.mobile .landing-page-body .hero .benefits .gallery ul{
  width:1140px
}
.preview.mobile .landing-page-body .hero .benefits .gallery ul li img{
  width:190px;
  height:408px
}
.preview.mobile .landing-page-body .hero .benefits .gallery ul.featured-2{
  margin:0 0 0 -190px
}
.preview.mobile .landing-page-body .hero .benefits .gallery ul.featured-3{
  margin:0 0 0 -380px
}
.preview.mobile .landing-page-body .hero .benefits .gallery ul.featured-4{
  margin:0 0 0 -570px
}
.preview.mobile .landing-page-body .hero .benefits .gallery ul.featured-5{
  margin:0 0 0 -760px
}
.preview.mobile .landing-page-body .hero .benefits .gallery ul.featured-6{
  margin:0 0 0 -950px
}
.preview.mobile .landing-page-body .hero .benefits:after{
  top:-20px;
  left:50%;
  right:auto;
  width:370px;
  height:315px;
  margin:0 0 0 -185px;
  background-image:url(../../../../assets/images/resellerLandingPage/iphone-cropped.png)
}
.preview.mobile .landing-page-body .hero .more{
  display:none
}
.preview.mobile .landing-page-body .block{
  display:block;
  max-width:720px;
  padding:0 0 50px;
  margin:0 auto
}
.preview.mobile .landing-page-body .block .video,
.preview.mobile .landing-page-body .block .banner,
.preview.mobile .landing-page-body .block .phones,
.preview.mobile .landing-page-body .block .pdf,
.preview.mobile .landing-page-body .block .screengrab{
  max-width:350px;
  margin:0 auto 30px;
  width: auto;
}
.preview.mobile .landing-page-body .block .phones img{
  max-height:265px
}
.preview.mobile .landing-page-body .block .video a{
  position:relative;
  display:block;
  width:100%;
  height:0;
  border-radius:4px;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;
  padding:56.25% 0 0;
  overflow:hidden;
  background:url(../../../../assets/images/resellerLandingPage/video.jpg) 50% 50% no-repeat;
  background-size:cover;
  box-shadow:0 0 0 0 transparent;
  -moz-box-shadow:0 0 0 0 transparent;
  -webkit-box-shadow:0 0 0 0 transparent
}
.preview.mobile .landing-page-body .block .screengrab img{
  width:190px;
  height:295px;
  margin-top: 10px;
  /*margin-left: -17px;*/
  max-width: unset;
}
.preview.mobile .landing-page-body .block .screengrab:after{
  top:-11px;
  width:370px;
  height:315px
}
.preview.mobile .landing-page-body .block .text {
  width: auto;
  max-width: 100%;
  text-align: center;
}
.preview.mobile .landing-page-body .block .text h2{
  font-size:20px;
  line-height:28px;
  margin:0 auto 15px
}
.preview.mobile .landing-page-body .block .text p{
  margin:0 auto 10px
}
.preview.mobile .landing-page-body .block .text .button{
  margin:30px auto 0
}
.preview.mobile .landing-page-body .block.text-right .screengrab:after,.preview.mobile .landing-page-body .block.text-left .screengrab:after{
  margin:0 0 0 -185px
}
.preview.mobile .landing-page-body .block.text-right .screengrab,
.preview.mobile .landing-page-body .block.text-left .screengrab{
  padding:0
}
.preview.mobile .landing-page-body .block.text-right .text,
.preview.mobile .landing-page-body .block.text-left .text{
  padding:0 15px
}
.preview.mobile .landing-page-body .grid{
  padding:0 20px;
  margin:45px auto
}
.preview.mobile .landing-page-body .grid h2{
  font-size:26px;
  line-height:35px
}
.preview.mobile .landing-page-body .grid .intro p{
  font-size:15px;
  line-height:24px
}
.preview.mobile .landing-page-body .grid .intro p strong{
  font-size:16px;
  line-height:25px
}
.preview.mobile .landing-page-body .grid ul{
  display:block;
  margin:40px 0
}
.preview.mobile .landing-page-body .grid ul li{
  width:auto;
  margin:0 0 20px
}
.preview.mobile .landing-page-body .grid ul li img{
  max-width:250px
}
.preview.mobile .landing-page-body .grid ul li:last-of-type{
  width:auto
}
.preview.mobile .landing-page-body .testimonial{
  padding:0 20px;
  margin:45px auto
}
.preview.mobile .landing-page-body .testimonial h2{
  font-size:28px;
  line-height:38px;
  margin:0 auto 25px
}
.preview.mobile .landing-page-body .world-land-trust{
  padding:0 20px;
  margin:45px auto
}
.preview.mobile .landing-page-body .world-land-trust .intro .logo{
  width:198px;
  height:96px
}
.preview.mobile .landing-page-body .world-land-trust .intro h2{
  font-size:20px;
  line-height:28px;
  margin:20px auto 15px
}
.preview.mobile .landing-page-body .world-land-trust .intro p{
  font-size:13px;
  line-height:22px;
  margin:0 auto 25px
}
.preview.mobile .landing-page-body .world-land-trust blockquote{
  padding:0 25px
}
.preview.mobile .landing-page-body .world-land-trust blockquote:before,
.preview.mobile .landing-page-body .world-land-trust blockquote:after{
  top:-10px;
  font-size:70px;
  line-height:70px
}
.preview.mobile .landing-page-body .world-land-trust blockquote:before{
  left:-12px
}
.preview.mobile .landing-page-body .world-land-trust blockquote:after{
  right:-12px
}
.preview.mobile .landing-page-body .registration{
  padding:0 20px;
  margin:45px auto
}
.preview.mobile .landing-page-body .registration h2{
  font-size:24px;
  line-height:32px
}
.preview.mobile .landing-page-body .registration p{
  font-size:15px
}
.preview.mobile .landing-page-body .registration .form{
  max-width:460px;
  margin:35px auto 0
}
.preview.mobile .landing-page-body .registration .form .field label{
  top:18px
}
.preview.mobile .landing-page-body .registration .form .field:nth-of-type(odd),
.preview.mobile .landing-page-body .registration .form .field:nth-of-type(even){
  margin:0 -20px 10px
}
.preview.mobile .landing-page-body .registration .form input[type="submit"]{
  min-width:230px;
  height:45px;
  font-size:12px;
  line-height:15px;
  box-shadow:0 5px 10px rgba(0,0,0,0.15);
  -moz-box-shadow:0 5px 10px rgba(0,0,0,0.15);
  -webkit-box-shadow:0 5px 10px rgba(0,0,0,0.15)
}
.preview.mobile .landing-page-body .copyright{
  margin:30px auto 40px
}
