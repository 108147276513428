.sketch-picker {
  background: #3C4046 !important;
  border: 1px solid #51555A !important;
  padding-top: 0 !important;
  &::before {
    content: 'Background color';
    display: block;
    margin: 7px 0;
  }

  .flexbox-fix {
    &:nth-child(2) {
      >div {
        &:first-child {
          >div {
            // &:nth-child(1) {
            //   top: 6px;
            //   overflow: visible;
            // }
            // &:nth-child(2) {
            //   display: none;
            // }
          }
        }
      }
    }

    // &:nth-child(3) {
    //   div {
    //     &:last-child {
    //       div {
    //         &:first-child {
    //           display: none;
    //         }
    //       }
    //     }
    //   }
    // }

    &:nth-child(4) {
      border-top: 1px solid #51555A !important;
    }

    input {
      background: #3C4046 !important;
      box-shadow: #51555A 0px 0px 0px 1px inset !important;
      color: #fff;
      font-weight: bold;
    }

    span {
      color: #fff !important;
      font-weight: bold;
    }
  }
}
