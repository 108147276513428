body {
  margin: 0;
  padding: 0;
  background: #262c31;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #4f5358;
  border-radius: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 86400s ease-in-out 0s; /* aftofilled inputs change colors after one day ;) */
}

@import url('https://fonts.googleapis.com/css?family=Inter|Work+Sans|Space+Mono|Space+Grotesk|Rubik|Libre+Franklin|Cormorant|Fira+Sans|Chivo|Eczar|Alegreya+Sans|Alegreya|Source+Sans+Pro|Source+Serif+Pro|Roboto|Roboto+Slab|BioRhyme|Inknut+Antiqua|Poppins|Archivo+Narrow|Libre+Baskerville|Playfair+Display|Karla|Lora|Proza+Libre|Spectral|IBM+Plex+Sans|Crimson+Text|Montserrat|Lato|PT+Sans|PT+Serif|Cardo|Neuton|Open+Sans|Inconsolata|Cabin|Raleway|Anonymous+Pro|Merriweather|Abril+FatFace|B612|Bitter|Cairo|Concert+One|Fjalla+One|Frank+Ruhl+Libre|Gentium+Basic|Josefin+Sans|Josefin+Slab|Muli|Nuito+Sans|Nunito|Old+Standard+TT|Overpass|Oxygen|Rajdhani|Rakkas|Titillium+Web|Ubuntu|Varela|Varela+Round|Vollkorn|Yatra+One|Zilla+Slab|Alata|Ibarra+Real+Nova&display=swap');

@font-face {
  font-family: 'F37 Ginger';
  src: url('./assets/fonts/F37Ginger-Bold/F37Ginger-Bold.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/F37Ginger-Bold/F37Ginger-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/F37Ginger-Bold/F37Ginger-Bold.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Bold/F37Ginger-Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Bold/F37Ginger-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/F37Ginger-Bold/F37Ginger-Bold.svg#7db0a735880422cc5fa2f936a390d651')
      format('svg'); /* Legacy iOS */

  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'F37 Ginger';
  src: url('./assets/fonts/F37Ginger-Bold-Italic/F37Ginger-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/F37Ginger-Bold-Italic/F37Ginger-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/F37Ginger-Bold-Italic/F37Ginger-BoldItalic.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Bold-Italic/F37Ginger-BoldItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Bold-Italic/F37Ginger-BoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/F37Ginger-Bold-Italic/F37Ginger-BoldItalic.svg#bb8d7a6ec0d5deda275935ab00ad83ba')
      format('svg'); /* Legacy iOS */

  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'F37 Ginger';
  src: url('./assets/fonts/F37Ginger-Regular/F37Ginger-Regular.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/F37Ginger-Regular/F37Ginger-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/F37Ginger-Regular/F37Ginger-Regular.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Regular/F37Ginger-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Regular/F37Ginger-Regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/F37Ginger-Regular/F37Ginger-Regular.svg#cd0eb1181545a540be3acd13cd87c831') format('svg'); /* Legacy iOS */

  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'F37 Ginger';
  src: url('./assets/fonts/F37Ginger-Italic/F37Ginger-Italic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/F37Ginger-Italic/F37Ginger-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/F37Ginger-Italic/F37Ginger-Italic.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Italic/F37Ginger-Italic.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Italic/F37Ginger-Italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/F37Ginger-Italic/F37Ginger-Italic.svg#630483153372c6803c45567348abcad0') format('svg'); /* Legacy iOS */

  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'F37 Ginger Light';
  src: url('./assets/fonts/F37Ginger-Light/F37Ginger-Light.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/F37Ginger-Light/F37Ginger-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/F37Ginger-Light/F37Ginger-Light.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Light/F37Ginger-Light.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Light/F37Ginger-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/F37Ginger-Light/F37Ginger-Light.svg#4a1efc7fd28af5705abda90f380987ed') format('svg'); /* Legacy iOS */

  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'F37 Ginger Light';
  src: url('./assets/fonts/F37Ginger-Light-Italic/F37Ginger-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/F37Ginger-Light-Italic/F37Ginger-LightItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/F37Ginger-Light-Italic/F37Ginger-LightItalic.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Light-Italic/F37Ginger-LightItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Light-Italic/F37Ginger-LightItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/F37Ginger-Light-Italic/F37Ginger-LightItalic.svg#1c7eff18379a490689d240dabfae793f')
      format('svg'); /* Legacy iOS */

  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'F37 Ginger Thin';
  src: url('./assets/fonts/F37Ginger-Thin/F37Ginger-Thin.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/F37Ginger-Thin/F37Ginger-Thin.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/F37Ginger-Thin/F37Ginger-Thin.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Thin/F37Ginger-Thin.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Thin/F37Ginger-Thin.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./assets/fonts/F37Ginger-Thin/F37Ginger-Thin.svg#f3e13b7d84e2887d1408955c128d7a0a')
      format('svg'); /* Legacy iOS */

  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: 'F37 Ginger Thin';
  src: url('./assets/fonts/F37Ginger-Thin-Italic/F37Ginger-ThinItalic.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/F37Ginger-Thin-Italic/F37Ginger-ThinItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/F37Ginger-Thin-Italic/F37Ginger-ThinItalic.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Thin-Italic/F37Ginger-ThinItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./assets/fonts/F37Ginger-Thin-Italic/F37Ginger-ThinItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/F37Ginger-Thin-Italic/F37Ginger-ThinItalic.svg#337ef199cf72f1b39d8898efcdfd3ac9')
      format('svg'); /* Legacy iOS */

  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: 'Noto Sans Javanese';
  src: url('./assets/fonts/Noto/NotoSansJavanese-Regular.ttf');
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay-Regular/SFProDisplay-Regular.ttf');
}

@font-face {
  font-family: 'Lucida Grande';
  src: url('./assets/fonts/LucidaGrande/LucidaGrande.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Lucida Grande';
  src: url('./assets/fonts/LucidaGrandeBold/LucidaGrandeBold.ttf');
  font-weight: 700;
}

.inside-list-margin {
  margin: 0 5px 0 25px;
}

.inside-list-margin-second {
  margin: 0 5px 0 60px;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

._outlinedButton:after {
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  border: 4px solid rgb(255 255 255 / 50%);
  height: calc(100% + 8px);
  content: '';
  position: absolute;
  border-radius: 50px;
}

._outlinedPlayButton:after {
  top: -1px;
  left: -3px;
  width: calc(100% + -2px);
  border: 4px solid rgb(255 255 255 / 50%);
  height: calc(100% + -1px);
  content: '';
  position: absolute;
  border-radius: 40px;
}

.hide {
  display: none;
}

.pos-r {
  position: relative;
}

@keyframes scrollDownAnimation {
  0%{
    opacity: 0;
    transform: rotate(45deg) translate(-10px,-10px);
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: rotate(45deg) translate(10px,10px);
  }
}

@media (max-width: 599.95px) {
  ._xsDownHide {
    display: none !important;
  }
}

@media (max-width: 959.95px) {
  ._smDownHide {
    display: none !important;
  }
}

@media (max-width: 1279.95px) {
  ._mdDownHide {
    display: none !important;
  }
}

@media (max-width: 1919.95px) {
  ._lgDownHide {
    display: none !important;
  }
}

@media (min-width: 0px) {
  ._xsUpHide {
    display: none !important;
  }
}

@media (min-width: 600px) {
  ._smUpHide {
    display: none !important;
  }
}

@media (min-width: 960px) {
  ._mdUpHide {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  ._lgUpHide {
    display: none !important;
  }
}


/* homepage styles - material ui has no solution for height-related media queries... */


@media (max-height: 800px) {
  .LandingSection_LandingText_ResponsiveHeight {
    font-size: 36px !important;
  }

  .LandingSection_PointHeader_ResponsiveHeight {
    min-height: 90px !important;
  }

  .LandingSection_PageSubtitle_ResponsiveHeight {
    margin-bottom: 32px !important;
  }
}

@media (max-height: 650px) {
  .LandingSection_Container_ResponsiveHeight {
    margin-top: 100px !important;
  }

  .LandingSection_LandingText_ResponsiveHeight {
    /*font-size: 30px !important;*/
  }

  .LandingSection_PointHeader_ResponsiveHeight {
    min-height: 10px !important;
  }
}
